const Termini = () => {
    return (
        <>
            <section className="termini">
                <h2>Raspored radionica i susreta</h2>
                <p>
                    Postavljanje konstelacija održava se grupno na radionicama ili kroz individualne susrete, online ili
                    uživo.
                </p>
                <br />
                <p>
                    <b>Mini radionice - grupne radionice uživo: </b>
                </p>
                <ul>
                    <li>
                        <p>
                            Mini radionica konstelacije: <b> 6.4.2025. 16:00-21:00</b>
                            <br /> na adresi: Nemčićeva 2, Zagreb, Apolon Centar
                        </p>
                        <p>
                            Tema: Na prošlom susretu smo započeli temu Pripadanje - neraskidiva potreba, veza i osjećaj
                            slobode. Nastavit ćemo još neka razmatranja na tu temu ali najviše ćemo se posvetiti redu i
                            poretku u obitelji i u životu i što se dešava kada idemo protiv ovog zakona ljubavi. Za
                            pripremu, možete napraviti genogram – simboličko obiteljsko stablo (upute su na ovoj
                            stranici na poveznici!). Pažnja - početak je u 16 sati!
                        </p>

                        <p>
                            Prijave na mini radionicu na:<b> vgaracic@gmail.com</b>
                        </p>
                    </li>
                    <li>
                        <p>
                            Mini radionica konstelacije: <b> 11.5.2025. 16:00-21:00</b>
                            <br /> na adresi: Nemčićeva 2, Zagreb, Apolon Centar
                        </p>
                        <p>
                            Tema:<b> Vrste emocija</b> - samo moje, emocije kojima komuniciram s drugima, sistemske
                            emocije (skupa moje i naše), meta emocije
                        </p>

                        <p>
                            Prijave na mini radionicu na:<b> vgaracic@gmail.com</b>
                        </p>
                    </li>
                    <li>
                        <p>
                            Mini radionica konstelacije: <b> 8.6.2025. 16:00-21:00</b>
                            <br /> na adresi: Nemčićeva 2, Zagreb, Apolon Centar
                        </p>
                        <p>
                            Tema:<b> Ravnoteža davanja i primanja</b>, osjećaj vrijednosti i vrijednost, samo sabotaža,
                            odnos s materijalnim i odnos sa novcem!
                        </p>

                        <p>
                            Prijave na mini radionicu na:<b> vgaracic@gmail.com</b>
                        </p>
                    </li>
                </ul>
                <p>
                    <b>Obiteljske i poslovne konstelacije - grupne radionice uživo: </b>
                </p>
                <ul>
                    <li>
                        <p>
                            Obiteljske i poslovne konstelacije - grupna radionica uživo: <b>30.3.2025. 14:00-19:00</b>
                            <br /> na adresi: Nemčićeva 2, Zagreb, Apolon Centar
                        </p>
                    </li>
                    <li>
                        <p>
                            Obiteljske i poslovne konstelacije - grupna radionica uživo: <b>13.4.2025. 14:00-19:00</b>
                            <br /> na adresi: Nemčićeva 2, Zagreb, Apolon Centar
                        </p>
                    </li>
                    <li>
                        <p>
                            Obiteljske i poslovne konstelacije - grupna radionica uživo: <b>27.4.2025. 14:00-19:00</b>
                            <br /> na adresi: Nemčićeva 2, Zagreb, Apolon Centar
                        </p>
                    </li>
                    <li>
                        <p>
                            Obiteljske i poslovne konstelacije - grupna radionica uživo: <b>25.5.2025. 14:00-19:00</b>
                            <br /> na adresi: Nemčićeva 2, Zagreb, Apolon Centar
                        </p>
                    </li>
                    <li>
                        <p>
                            Obiteljske i poslovne konstelacije - grupna radionica uživo: <b>15.6.2025. 14:00-19:00</b>
                            <br /> na adresi: Nemčićeva 2, Zagreb, Apolon Centar
                        </p>
                    </li>
                </ul>
                <p>
                    Prijave na radionice na:<b> ankica.konstelacije@gmail.com</b>
                </p>
                <br />
                <p>
                    <b>Obiteljske i poslovne konstelacije - grupne radionice online: </b>
                </p>
                <ul>
                    <li>
                        <p>
                            Obiteljske konstelacije - grupna online radionica: <b>12.4.2025. 14:00-19:00</b>
                        </p>
                    </li>
                    <li>
                        <p>
                            Obiteljske konstelacije - grupna online radionica: <b>10.5.2025. 14:00-19:00</b>
                        </p>
                    </li>
                    <li>
                        <p>
                            Obiteljske konstelacije - grupna online radionica: <b>14.6.2025. 14:00-19:00</b>
                        </p>
                    </li>
                </ul>
                <p>
                    Prijave na radionice na:<b> ankica.konstelacije@gmail.com</b>
                </p>
                <br />
                <p>
                    <b>Cijene:</b>
                    <br />
                    Cijena postavljanja, grupno ili individualno 80 €. Cijena za jednu mini radionicu 40 €, online
                    praćenje mini- radionice 30€, uplata za paket od tri radionice 90€.
                </p>
                <br />
                <p>
                    <b>Novosti:</b>
                    U ožujku male novosti - počinjem sa serijom predavanja Sistemski zakoni ljubavi - kako ih prepoznati
                    i sljediti.
                    <br /> Predavanja će biti u obliku trosatne mini radionice. Nećemo postavljati osobne teme i pitanja
                    već ćemo razmotriti neke od sistemskih zakonitosti i raditi manje postave vezane uz temu mini
                    radionice namijenjene svima sudionicima.
                    <br /> Mini radionice će biti jednom mjesečno, a teme su:
                </p>
                <ul>
                    <li>
                        <p>
                            <b>1. Pripadanje</b> <br />- neraskidiva potreba, veza i osjećaj slobode <br />- tko sve
                            pripada u sistem <br />- isključenje i nadomještanje isključenih <br />- živjeti tuđi život
                            i sudbinu <br />- sloboda i krivnja
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>2. Poredak</b> <br />- tko ima prioritet <br />- držanje tuđeg mjesta
                            <br />- pomaganje i odmaganje <br />- parentifikacija
                            <br />- ja sam mala/i, ti si velika/i
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>3. Vrste emocija:</b> <br />- osobne (primarne) <br />- međusobne (sekundarne,
                            manipulativne)
                            <br />- sistemske i metaemocije
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>4. Ravnoteža davanja i primanja</b> <br />- u obiteljskim odnosima <br />- u partnerskim
                            odnosima
                            <br />- u ostalim komunikacijama
                        </p>
                    </li>
                </ul>
                <br />
                <br />
                <p>
                    <b>Na radionici obiteljskih konstelacija raspetljavat ćemo:</b>
                </p>
                <ul>
                    <li>ponavljajuća iskustva i neuspjesi</li>
                    <li>problemi u vezama i obiteljskim odnosima</li>
                    <li>problemi u odnosima sa drugim ljudima</li>
                    <li>ponavljanje besmislenih sukoba</li>
                    <li>bijes i kronična ljutnja i nezadovoljstvo</li>
                    <li>bolesti, kronične i simptomatske</li>
                    <li>depresija, ovisnosti i destruktivna ponašanja</li>
                    <li>bilo koja životna situacija u kojoj osjećamo nemoć</li>
                    <li>zid pred nama u kojeg hipnotizirano buljimo, nemoćni za okret</li>
                    <li>potreba da živimo drugačije</li>
                    <li>osjećaj da ne propadaš u obitelji</li>
                </ul>
                <p>
                    <b>Na radionici poslovnih konstelacija ćemo postavljati i raspetljati poslovna pitanja:</b>
                </p>
                <ul>
                    <li>kako firmu/posao postaviti na noge, da dođe do uspjeha (i novaca)</li>
                    <li>uloga i odnosi vlasnika i zaposlenika</li>
                    <li>što nudim i kako odrediti cijenu rada/usluge</li>
                    <li>klijenti - kako ih pronaći i zadržati</li>
                    <li>jesam li na pravom mjestu u firmi, jesam li u pravoj firmi za mene</li>
                    <li>imam više mogućnosti razvoj karijere, kako odabrati? Glavom ili srcem!</li>
                    <li>iako se trudim i ulažem srcem – poslovno i financijski ne uspijevam</li>
                </ul>
            </section>
            {/* <p>
                    Termini individualnih susreta, uživo ili on-line su po dogovoru.
                    <br /> Prijave su na mail: <b> ankica.konstelacije@gmail.com </b>
                </p> */}
            {/* <ul>
                    <li>
                         - 
                    </li>
                    <li>
                        <b>2.11.2023.</b>
                    </li>
                    <li>
                        <b>16.11.2033</b>
                    </li>
                </ul> */}
        </>
    );
};

export default Termini;
